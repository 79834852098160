@import "./fonts.css";
@import "./animations/index.css";

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

* {
  font-family: "NAMU 1850";
  font-weight: normal;
  font-style: normal;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}

.about-link,
.about-text {
  width: 100%;
  margin: 25px 0;
}

.about-link a {
  color: white;
}

.app {
  background-color: black;
}

::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.black-screen {
  text-align: justify;
  white-space: pre-line;
  background-color: black;
  font-family: "NAMU Pro";
  padding: 1rem;
  /* padding-right: 0px; */
  /*white-space: pre-wrap;*/
  /* padding-right: 3rem; */
}
.black-screen > img {
  width: 100%;
}

.mirror {
  transform: scaleX(-1);
}

.mirror > img {
  transform: scaleX(-1);
}

.mirror > div {
  transform: rotateY(180deg);
}

button.slick-arrow {
  display: none !important;
}

.languageContainer {
  background-color: black;
  color: white;
  height: 100%;
}

.chooseLanguage {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
}

div.intro {
  height: 100%;
  background-color: black;
  color: white;
}

.start {
  position: relative;
  height: 90%;
}

div.languages {
  background-color: black;
  color: white;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 30vw;
}

div.languages > div {
  cursor: pointer;
}

div.languages > div:hover {
  font-family: "NAMU 1930";
}

.slick-slider,
.slick-slide,
.slick-slide > div,
.slick-list,
.slick-track {
  height: 100% !important;
  animation: bg 1s linear;
  background-size: cover;
  background-color: white;
}

.test-slide {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-rotation {
  /*filter: drop-shadow(-100px 100px 50px rgba(0, 0, 0, 0.25));*/
  /*-webkit-box-shadow: 5px 5px 28px -8px #000000; 
box-shadow: 5px 5px 28px -8px #000000;*/
}

.app {
  /*display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);*/
  color: black;
  font-size: 35px;
  background-size: cover;
  display: flex;
}

#slider-wrapper {
  width: 100%;
  height: 100%;
  background-color: white;
}

@keyframes in {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}

@keyframes move {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: rotate(30deg);
  }
  100% {
    transform: translateX(-300px);
  }
}

.animated-item {
  position: absolute;
}

.bust {
  bottom: 0;
  left: 82%;
  animation: move 1s normal;
}

#picture-2 {
  animation: out 0.2s ease-in;
}

#picture-2:hover {
  animation: in 0.2s ease-in;
  transform: scale(1.05);
  cursor: pointer;
}

.control {
  z-index: 999;
  position: absolute;
  top: calc(50% - 50px);
  background-color: black;
  height: 166px;
  width: 166px;
  /*border-radius: 100%;*/
  cursor: pointer;
  transition: all ease-in-out;
}
.control-left {
  left: 2%;
  border-radius: 50% !important;
}

.control-right {
  right: 2%;
  border-radius: 50% !important;
}

.screen {
  position: absolute;
  width: 80%;
  overflow: hidden;
  height: 100%;
  background: black;
  color: white;
  text-align: justify;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  word-wrap: break-word;
  flex-wrap: wrap;
  z-index: 999;
}

.screen-content {
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  text-align: justify;
}

.text-title {
  background-color: black;
  color: white;
  height: 370px;
  width: 70%;
  /* overflow-y: scroll; */
  overflow-x: hidden;
  position: relative;
  padding: 40px;
}

.text-title .title {
  height: 100%;
  width: 100%;
  font-size: 200px;
  text-align: center;
}

.text-title .action {
  padding: 40px;
}

.text-title .text {
  width: 100%;
  padding-top: 40px;
}

.slide-rotation {
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-title .action {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 40px;
}

#slider-wrapper > div {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

#slider-wrapper > div > div {
  width: 100%;
}

div[class*="bg"] {
  position: fixed;
}

.test-slide {
  background-color: white;
}

.right-right-enter,
.right-left-enter,
.left-right-enter,
.left-left-enter,
.right-right-exit,
.right-left-exit,
.left-right-exit,
.left-left-exit {
  transition: transform 1000ms ease-out;
}

.right-left-enter,
.right-right-enter,
.left-right-enter,
.left-left-enter {
  transform: translateX(100%);
}

.left-left-enter {
  transform: translateX(-100%);
}

.right-right-enter.right-right-enter-active,
.left-left-enter.left-left-enter-active,
.left-right-enter.left-right-enter-active,
.right-left-enter.right-left-enter-active {
  transform: translateX(0%);
}

.left-left-exit,
.left-right-exit,
.right-right-exit,
.right-left-exit {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(0%);
}

.right-right-exit-active {
  transform: translateX(-100%);
}

.right-left-exit-active {
  transform: translateX(-100%);
}

.left-right-exit-active {
  transform: translateX(100%);
}
.left-left-exit-active {
  transform: translateX(100%);
}

.back-side {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  /* align-items: baseline; */
  justify-content: start;
  color: white;
  top: 47px;
}

@keyframes resize-screen {
  0% {
    width: 100%;
  }
  10% {
    width: 100%;
  }
  100% {
    width: 20%;
  }
}

@keyframes resize-screen-mobile-right {
  0% {
    width: 100%;
  }
  10% {
    width: 100%;
  }
  100% {
    width: 50%;
  }
}

@keyframes resize-screen-mobile-left {
  0% {
    width: 100%;
  }
  10% {
    width: 100%;
  }
  100% {
    width: 40%;
  }
}

@keyframes move-screen-left {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes move-screen-right {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(25%);
  }
}

@keyframes move-screen-left {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes move-screen-right-mobile {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(35%);
  }
}

@keyframes move-screen-right-mobile-x {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(65%);
  }
}

@keyframes fade-screen-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}

@keyframes fade-screen-right {
  0% {
    transform: translateX(25%);
  }
  100% {
    transform: translateX(200%);
  }
}

@keyframes fade-screen-mobile {
  0% {
    transform: translateX(65%);
  }
  100% {
    transform: translateX(200%);
  }
}

#slider-wrapper > div.resize-right {
  position: absolute;
  right: 0;
  animation: resize-screen 1s ease-in;
  width: 20%;
}

.animated-item img {
  display: none;
}

#slider-wrapper > div.resize-left {
  position: absolute;
  left: 0;
  animation: resize-screen 1s ease-in;
  width: 10%;
}

.screen-left {
  transform: translateX(-200%);
}

.screen-left > img {
  padding: 20px 20px 20px 20px;
}

.screen-left.fade {
  animation: fade-screen-left 1s cubic-bezier(0.23, 0.61, 0.29, 0.87);
}

.screen-left.visible {
  transform: translateX(0);
  animation: move-screen-left 1s cubic-bezier(0.23, 0.61, 0.29, 0.87);
}

.screen-right {
  transform: translateX(200%);
}

.screen-right > img {
  padding: 20px 20px 20px 20px;
  width: 94% !important;
}

.screen-right.fade {
  animation: fade-screen-right 1s cubic-bezier(0.23, 0.61, 0.29, 0.87);
}

.screen-right.visible {
  transform: translateX(25%);
  animation: move-screen-right 1s cubic-bezier(.23,.61,.29,.87);
}

.animated-item {
  position: absolute;
}

.burgerMenu {
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 999;
  cursor: pointer;
}

.back-side div:nth-child(2) {
  background-color: black;
  padding: 2rem;
  /* overflow: scroll !important; */
}

.screen-left > div,
.screen-right > div {
  height: 100%;
  display: flex;
}

#menu > div:nth-child(2),
#menu > div:nth-child(4),
#menu > div:nth-child(6) {
  display: block;
}

.screen-left > div > img,
.screen-right > div > img {
  width: 100%;
  max-height: 100%;
  align-self: center;
}

@media screen and (min-width: 700px) and (max-width: 730px) {
  .screen-right.visible {
    animation: move-screen-right-mobile-x 1s
      cubic-bezier(0.23, 0.61, 0.29, 0.87) !important;
    transform: translateX(65%) !important;
  }
}

/* On screens that are 600px or less */
@media screen and (max-width: 1024px) {
  .about-link,
  .about-text {
    font-size: 16px;
  }

  .screen-left > div,
  .screen-left > div > img,
  .screen-right > div,
  .screen-right > div > img {
    height: 100%;
    width: auto !important;
  }

  .screen-right.visible {
    transform: translateX(35%);
    animation: move-screen-right-mobile 1s cubic-bezier(0.23, 0.61, 0.29, 0.87);
  }

  .screen {
    width: fit-content !important;
  }

  .body {
    overflow-x: hidden;
    overflow-y: hidden !important;
    width: 100%;
    height: 100%;
  }
  #root {
    overflow-x: hidden;
    overflow-y: hidden !important;
    width: 100%;
    height: 100%;
  }

  .control-right,
  .control-left {
    height: 120px;
    width: 120px;
  }

  .black-screen {
    font-size: 14px;
    /*height: -webkit-fill-available;*/
  }

  .black-screen img {
    font-size: 14px;
    /*height: -webkit-fill-available;*/
  }

  .control-right {
    right: -70px !important;
  }

  .control-left {
    left: -70px !important;
  }

  .burgerMenu {
    top: 10px;
    height: 10%;
  }

  .flip-card {
    overflow: scroll;
  }

  .flip-card-front,
  .flip-card-back {
    overflow: inherit !important;
  }

  #menu > div {
    font-size: 10px;
  }

  #slider-wrapper > div.resize-right {
    animation: resize-screen-mobile-right 1s ease-in-out;
    width: 50%;
  }

  #slider-wrapper > div.resize-left {
    animation: resize-screen-mobile-left 1s ease-in-out;
    width: 40%;
  }
}

.scroll-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  z-index: 110;
}

/*Card*/

.flip-card {
  background-color: transparent;
  width: 50%;
  height: 60%;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  z-index: 100;
  transition: all 0.8s ease-out;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card.clicked .flip-card-inner {
  transform: rotateY(180deg);
}

/*.flip-card.clicked .flip-card-front{
  visibility: hidden;
}*/

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  color: white;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  overflow: scroll;
}

/* Style the back side */
.flip-card-back {
  color: white;
  transform: rotateY(180deg);
}

#menu {
  /*padding: 20px;*/
}

#menu > div {
  height: auto !important;
  text-indent: 1.5em;
  padding: 0 40px;
}

#menu > .about,
#menu > .project {
  text-indent: 0;
}

#menu a {
  color: #f58751;
}

#menu a:hover {
  font-family: "NAMU 1930";
  /*text-decoration: underline;*/
  color: #f58751;
}

#menu > .project {
  padding: 80px 40px 20px 40px;
}

#menu > div > div {
  margin: 40px 0;
}

@media only screen and (min-device-width: 800px) and (max-device-width: 850px) and (-webkit-device-pixel-ratio: 3) {
  .screen-right.visible {
    transform: translateX(65%);
    animation: move-screen-right-mobile-x 1s
      cubic-bezier(0.23, 0.61, 0.29, 0.87);
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .screen-left.visible,
  .screen-right.visible {
    width: 75% !important;
  }

  .screen-left.visible img,
  .screen-left.visible div,
  .screen-right.visible img,
  .screen-right.visible div {
    width: 100% !important;
  }

  .screen-left.visible img,
  .screen-right.visible img {
    height: auto !important;
  }
}
