.kadirova_start {
	left: 60.89%;
	top: 75.85%;
	z-index: 9;
   /*  animation: movel 1s forwards ease-in; */
  }
.start_start {
	left: 54.17%;
	top: 61.15%;
	z-index: 8;
   /*  animation: movel 1s forwards ease-in; */
  }
  .rinok_start {
	left: 42.71%;
	top: 40.83%;
	z-index: 7;
   /*  animation: movel 1s forwards ease-in; */
  }
  .stolb_start {
	left: 22.5%;
	top: 34.26%;
	z-index: 6;
   /*  animation: movel 1s forwards ease-in; */
  }
  .bord_start {
	left: 50.94%;
	top: 11.76%;
	z-index: 5;
   /*  animation: movel 1s forwards ease-in; */
  }
  .bord2_start {
	left: 4.84%;
	top: 0.56%;
	z-index: 4;
   /*  animation: movel 1s forwards ease-in; */
  }
  .fripulia1_start {
	left: 52.6%;
	top: 3.24%;
	z-index: 3;
   /*  animation: movel 1s forwards ease-in; */
  }
  .fripulia2_start {
	left: 62.19%;
	top: 3.24%;
	z-index: 2;
   /*  animation: movel 1s forwards ease-in; */
  }
  .fripulia3_start{
	left: 73.54%;
	top: 5.46%;
	z-index: 1;
   /*  animation: movel 1s forwards ease-in; */
  }
  .bg_start {
	background-image: url('./img/10_bg_0_0.png');
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	z-index: 0;
  }
