@font-face {
  font-family: "NAMU 1850";
  src: url("./fonts/NAMU-1850.woff2") format("woff2"),
    url("./fonts/NAMU-1850.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "NAMU Pro";
  src: url("./fonts/NAMU-Pro.woff2") format("woff2"),
    url("./fonts/NAMU-Pro.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "NAMU 1400";
  src: url("./fonts/NAMU-1400.woff2") format("woff2"),
    url("./fonts/NAMU-1400.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "NAMU 1930";
  src: url("./fonts/NAMU-1930.woff2") format("woff2"),
    url("./fonts/NAMU-1930.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "NAMU 1960";
  src: url("./fonts/NAMU-1960.woff2") format("woff2"),
    url("./fonts/NAMU-1960.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "NAMU 1910";
  src: url("./fonts/NAMU-1910.woff2") format("woff2"),
    url("./fonts/NAMU-1910.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "NAMU 1990";
  src: url("./fonts/NAMU-1990.woff2") format("woff2"),
    url("./fonts/NAMU-1990.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "NAMU 1750";
  src: url("./fonts/NAMU-1750.woff2") format("woff2"),
    url("./fonts/NAMU-1750.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "NAMU 1600";
  src: url("./fonts/NAMU-1600.woff2") format("woff2"),
    url("./fonts/NAMU-1600.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
